import { css } from 'styled-components';

export const minWidth = '720';
export const midWidth = '1024';
export const maxWidth = '1440';

export const makeTextSize = (minSize, maxSize, minSizeBig, maxSizeBig) => {
    /* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));*/
    return `
    font-size: ${minSize}px;
    font-size: calc(${minSize}px + ${maxSize - minSize} * (100vw - ${minWidth}px)/${
        midWidth - minWidth
    });
    @media (min-width: ${minWidth}px) {
      font-size: ${minSizeBig}px;
      font-size: calc(${minSizeBig}px + ${maxSizeBig - minSizeBig} * (100vw - ${midWidth}px)/${
        maxWidth - midWidth
    });
    }
  `;
};

export const textSizesStyles = {
    p3: css`
        margin-bottom: 10vh;
        ${makeTextSize(15, 19, 10, 12)};
    `,
    p2: css`
        margin-bottom: 10vh;
        ${makeTextSize(18, 20, 20, 20)};
    `,
    p1: css`
        margin-bottom: 10vh;
        ${makeTextSize(18, 20, 20, 24)};
    `,
    lead: css`
        margin-bottom: 4.5vh;
        ${makeTextSize(20, 20, 22, 22)};
    `,
    pIntro: css`
        margin-bottom: 1vh;
        line-height: calc(0.25rem + 1em);
        ${makeTextSize(30, 40, 28, 36)};
    `,
    h3: css`
        text-transform: uppercase;
        margin-bottom: 0.5vh;
        ${makeTextSize(35, 48, 30, 42)};
    `,
    h2: css`
        text-transform: uppercase;
        ${makeTextSize(37, 48, 40, 48)};
    `,
    h1: css`
        text-transform: uppercase;
        ${makeTextSize(53, 72, 56, 72)};
    `,
    hero: css`
        text-transform: uppercase;
        letter-spacing: -0.0333333rem;
        line-height: calc(0.25rem + 0.8em);
        ${makeTextSize(59, 72, 120, 140)};
    `,
};
