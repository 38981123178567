import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import BaseStyles from "./components/base-styles";
import Home from "../src/pages/home";
import Playfulbet from "./pages/playfulbet";
import Typeform from "./pages/typeform";
import NewRelic from "./pages/new-relic";
import Work from "./pages/work";

class App extends Component {
  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <BaseStyles />
        <Route exact path="/" component={Home} />
        <Route path="/playfulbet" component={Playfulbet} />
        <Route path="/typeform" component={Typeform} />
        <Route path="/new-relic" component={NewRelic} />
        <Route path="/work" component={Work} />
      </BrowserRouter>
    );
  }
}

export default App;
