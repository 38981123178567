import { createGlobalStyle } from 'styled-components';

const BaseStyles = createGlobalStyle`
  html, body {
    font-size: 18px;
    line-height: 1.25;
  }

  body {
    font-family: neue-haas-grotesk-text, -apple-system, BlinkMacSystemFont, sans-serif;
    color: #192029;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4 {
    font-family: neue-haas-grotesk-display, -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: calc(0.25rem + 0.9em);
    margin: 0;
  }

  p {
    font-weight: 400;
    font-style: normal;
    margin: 0;
  }

  ::-moz-selection {
  	color: white;
  	background-color: black;
  }
	::selection {
		color: white;
		background-color: black;
	}

	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	img {
		max-width: 100%;
	}

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  i {
    font-style: normal;
  }
`;

export default BaseStyles;
