import React from 'react';
import styled from 'styled-components';
import { minWidth } from '../variables';

const LayoutWrapper = styled.main`
    width: 100%;
    padding: 2vh 2vw;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0.25vw;
    grid-auto-flow: row;

    section {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
        border-bottom: 1px solid;
        padding-bottom: 10vh;
        margin-bottom: 4vh;
    }

    > h2:first-of-type {
        grid-column: 1 / -1;

        @media (min-width: ${minWidth}px) {
            grid-column: 4 / -3;
        }
    }

    h2 {
        grid-column: 1 / -1;

        @media (min-width: ${minWidth}px) {
            grid-column: 1 / 4;
            position: sticky;
            top: 2vh;
        }
    }

    p {
        grid-column: 1 / -1;

        @media (min-width: ${minWidth}px) {
            grid-column: 4 / -3;
        }
    }
`;

class Layout extends React.Component {
    render() {
        return (
            <LayoutWrapper className={this.props.className}>{this.props.children}</LayoutWrapper>
        );
    }
}

export default Layout;
