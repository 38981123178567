import React from 'react';
import styled from 'styled-components';
import { textSizesStyles } from '../variables';

const Text = styled.div`
    ${(props) => textSizesStyles[props.kind]};
`;

class Content extends React.Component {
    render() {
        return (
            <Text as={this.props.tag} kind={this.props.kind} className={this.props.className}>
                {this.props.children}
            </Text>
        );
    }
}

Content.defaultProps = {
    tag: 'p',
    kind: 'lead',
};

export default Content;
