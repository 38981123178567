import React from 'react';
import styled from 'styled-components';
import Content from './content';
import { minWidth } from '../variables';

const HeaderContent = styled.div`
    grid-column: 1 / -1;

    @media (min-width: ${minWidth}px) {
        grid-column: 8 / -1;
        display: flex;
        flex-direction: column;
    }
`;

const HeaderTitle = styled(Content)`
    grid-column: 1 / -1;
`;

const HeaderQuote = styled.blockquote`
    grid-column: 1 / -1;
    margin-top: 1vh;
    margin-bottom: 15vh;
    padding: 0;
    margin-left: 0;

    @media (min-width: ${minWidth}px) {
        grid-column: 4 / -3;
    }
`;
const HeaderQuoteContent = styled(Content)`
    @media (min-width: ${minWidth}px) {
    }
`;

const HeaderQuoteAuthor = styled(Content)`
    grid-column: 1 / -1;

    @media (min-width: ${minWidth}px) {
        grid-column: 4 / -2;
    }
`;

const HeaderImage = styled.img`
    object-fit: cover;
    width: 100%;
    grid-column: 1 / -1;
    aspect-ratio: 3 / 2;
    height: 100%;

    @media (min-width: ${minWidth}px) {
        grid-column: 1 / 8;
        aspect-ratio: 7 / 2;
    }
`;

const CaseHeader = ({
    title,
    role,
    time,
    imgSrc,
    alt,
    description,
    quote,
    quoteAuthor,
    quoteAuthorRole,
}) => {
    return (
        <>
            <HeaderTitle kind="hero" tag="h1">
                {title}&mdash;{role} <time dateTime={time}>{time}</time>
            </HeaderTitle>
            <HeaderImage alt={alt} src={imgSrc} />
            <HeaderContent>
                <Content kind="h2" tag="h2">
                    {description}
                </Content>
            </HeaderContent>
            <HeaderQuote>
                <HeaderQuoteContent kind="pIntro" tag="p">
                    “{quote}
                </HeaderQuoteContent>
                <HeaderQuoteAuthor>
                    &mdash;{quoteAuthor}. {quoteAuthorRole}.
                </HeaderQuoteAuthor>
            </HeaderQuote>
        </>
    );
};

export default CaseHeader;
