import React from 'react';
import Content from './content';
import styled from 'styled-components';
import { minWidth } from '../variables';

const Figure = styled.figure`
    grid-column: 1 / -1;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: subgrid;
    align-items: start;

    @media (min-width: ${minWidth}px) {
        grid-column: 4 / -1;
    }
`;
const FigVideo = styled.video`
    display: block;
    width: 100%;
    height: auto;
    grid-column: 1 / -1;
    margin-bottom: 4vh;
    background-color: #f2f2f2;

    @media (min-width: ${minWidth}px) {
        grid-column: 1 / -3;
    }
`;
const FigCaption = styled(Content)`
    grid-column: 1 / -1;

    @media (min-width: ${minWidth}px) {
        position: sticky;
        top: 2vh;
        align-items: start;
        grid-column: -3 / -2;
    }
`;

const Video = ({ alt, src }) => {
    return (
        <Figure>
            <FigVideo width="100%" controls autoPlay loop playsInline src={src} alt={alt} />
            <FigCaption tag="figcaption" kind="p3">
                {alt}
            </FigCaption>
        </Figure>
    );
};

export default Video;
