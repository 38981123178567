import React from 'react';
import styled from 'styled-components';
import Content from '../components/content';
import { minWidth } from '../variables';
import Image1 from '../assets/images/home/home-1.jpg';
import Image2 from '../assets/images/home/home-2.jpg';

const Layout = styled.div`
    width: 100%;
    padding: 2vh 2vw;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0.25vw;
    grid-auto-flow: column;

    h1,
    h2 {
        grid-column: 1 / -1;
    }

    img {
        object-fit: cover;
        width: 100%;
        display: block;
        background-color: currentColor;
    }

    & :nth-child(2) {
        grid-column: 1 / 7;
        height: 100%;
        aspect-ratio: 12 / 2;
    }

    & :nth-child(3) {
        grid-column: 7 / -1;
    }

    & :nth-child(4) {
        @media (min-width: ${minWidth}px) {
            grid-column: 3 / 11;
        }
    }

    & :nth-child(5) {
        grid-column: 3 / -1;
        aspect-ratio: 3 / 2;
    }

    & :nth-child(6) {
        grid-column: 1 / 6;
    }

    & :nth-child(7) {
        grid-column: 8 / -1;
        text-align: right;
    }
`;

class Home extends React.Component {
    render() {
        return (
            <Layout>
                <Content kind="hero" tag="h1">
                    {"I'm Josep Martins, a product designer and developer based in Girona."}
                </Content>
                <img fetchpriority="high" alt="Textured graffiti wall in Barcelona" src={Image1} />
                <Content kind="h2" tag="h2">
                    Previously at <a href="https://github.com/">GitHub</a> as systems designer ,{' '}
                    <a href="https://newrelic.com/">New Relic</a> ,{' '}
                    <a href="https://typeform.com/">Typeform</a> and{' '}
                    <a href="https://docplanner.com/">Docplanner</a>.
                </Content>
                <Content kind="h1" tag="h2">
                    My side projects include a{' '}
                    <a href="https://boringavatars.com/">react library for generating avatars</a>, a{' '}
                    <a href="https://boringvectors.com/">
                        tool for transforming images into SVG assets
                    </a>
                    , and a <a href="https://unsplash.com/@josepmartins">free texture archive</a>.
                </Content>
                <img
                    style={{ marginTop: '0.5em' }}
                    fetchpriority="low"
                    loading="lazy"
                    decoding="async"
                    alt="Textured graffiti wall"
                    src={Image2}
                />
                <Content kind="h2" tag="h2">
                    Find me on <a href="https://github.com/josepmartins/">Github</a>,{' '}
                    <a href="https://www.instagram.com/josepmartins/">Instagram</a>,{' '}
                    <a href="https://twitter.com/josep_martins/">Twitter</a> or{' '}
                    <a href="https://www.linkedin.com/in/josepmartins/">Linkedin</a>.
                </Content>
                <Content kind="h2" tag="h2">
                    Or send me an <a href="mailto:jsp.mrtns@gmail.com">email</a>.
                </Content>
            </Layout>
        );
    }
}

export default Home;
