import React from 'react';
import Content from '../components/content';
import Image from '../components/image';
import Footer from '../components/footer';
import Layout from '../components/layout';
import CaseHeader from '../components/case-header';

const Typeform = () => (
    <Layout>
        <CaseHeader
            title="Typeform"
            role="Product Design"
            time="2017&ndash;2019"
            imgSrc={require('../assets/images/typeform/header-1.jpg')}
            alt="Designstudio 2019 Typeform's rebrandring visuals"
            description="Typeform is an online form builder that helps users build conversational and visually captivating forms."
            quote="Obsessing over things that most people don’t notice is what creates a great user experience. We spend time with things like nailing transition screens and alignments between visual elements."
            quoteAuthor="David Okuniev"
            quoteAuthorRole="Founder of Typeform"
        />
        <section>
            <Content kind="p" tag="h2">
                Intro
            </Content>
            <Content>
                In 2017, Typeform was immersed in a complete{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.typeform.com/blog/inside-story/typeform-version-2/"
                >
                    platform redesign
                </a>
                . Teams needed to validate early assumptions by conducting user tests with static
                mockups, which ended up being inefficient when exploring certain interactions.
            </Content>
        </section>
        <section>
            <Content kind="p" tag="h2">
                Design Technologist
            </Content>
            <Content>
                During that exploratory phase, I worked with Product Designers and PMs from
                different teams to deliver HTML prototypes for the user testing sessions. This gave
                us more insights into how to improve certain flows and navigation issues while
                seeing users interact with a close-to-real product.
            </Content>
            <Content>
                This prototype phase needed to be agile, and writing fake, dynamic content was the
                most consuming part. I built a{' '}
                <a href="https://blog.greenroots.info/faking-is-good-back-end-data-and-api-prototyping-with-fakerjs-and-json-server">
                    custom API
                </a>{' '}
                that served random content. Fetching data from it will return a JSON file with
                names, dates, question titles, or images on the spot. That improved the building
                process and helped teams detect edge cases such as large titles, names, or different
                date formats.
            </Content>
            <Image
                alt="JSON file sample (left) with dynamically generated content. The prototype (right) filled with the new content ready to be used in user test sessions."
                src={require('../assets/images/typeform/fake-data-json.jpg')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                Sampler Design System
            </Content>
            <Content>
                As the new version was released, I jumped into the team dedicated to maintaining and
                optimizing Typeform's forms as a Product Designer. For the past four years, form
                components and new features were created on top of each other, which helped deliver
                fast but led to inconsistent UI elements, a lack of responsiveness, and performance
                issues. The company's long-term vision involved new features, a new set of question
                types, and tools for design customization.
            </Content>
            <Content>
                With the help of the DS team, led by <a href="https://hayk.design/#/">Hayk An</a>,
                we built{' '}
                <a href="https://typeform.design/sampler/?path=/story/docs-welcome--page">
                    Sampler
                </a>
                , the Design System of Typeform's forms, to solve the existing issues and help us
                scale on the upcoming features.
            </Content>
            <Content>
                I conducted an audit to propose a new{' '}
                <a href="https://typeform.design/sampler/?path=/story/form-elements-text--examples">
                    typography scale
                </a>{' '}
                that could cover all the UI elements and be reactive to different screen sizes. We
                also defined a new Layout structure: grid, margins, spacing, and responsive logic.
            </Content>
            <Image
                alt="The Sampler typographic scale (still in use)."
                src={require('../assets/images/typeform/typographic-scale-example-sampler.jpg')}
            />
            <Image
                alt="Pairing UI elements to the new scale"
                src={require('../assets/images/typeform/small-breakpoint-typography.jpg')}
            />
            <Content>
                With those foundations, we develop essential form elements, such as{' '}
                <a href="https://typeform.design/sampler/?path=/story/form-elements-button--default-story&amp;args=contentColor:!hex(ffffff);color:!hex(61a97c)">
                    Buttons
                </a>{' '}
                and{' '}
                <a href="https://typeform.design/sampler/?path=/story/form-elements-input--example">
                    Inputs
                </a>
                , and combine them to create others like the{' '}
                <a href="https://typeform.design/sampler/?path=/story/typeform-specific-question-header--default">
                    Question header
                </a>{' '}
                or the{' '}
                <a href="https://typeform.design/sampler/?path=/story/typeform-specific-screen-screen-layouts--stack-layout">
                    Screen
                </a>{' '}
                components.
            </Content>
            <Content>
                For instance, Multiple Choice and Dropdown components are composed of Text and
                Button components. These elements adapt their color scheme depending on the
                customer's color choices to ensure enough contrast.
            </Content>
            <Image
                alt="The Question component using Text and RadioButton components."
                src={require('../assets/images/typeform/screen-component-sampler.jpg')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                The Builder
            </Content>
            <Content>
                After setting up the foundations of the Sampler design system, I was part of the
                team that managed the entire form creation of a Typeform. The objective was to
                improve the activation rates through new functionalities. Data and user interviews
                showed us that Typeforms with custom images, backgrounds, or in-brand colors had
                better engagement and completion rates, leading to a better activation number.
            </Content>
            <Content>
                We worked on improving the existing design tools, re-doing the Design theme panel
                from scratch, and adding new tools and features, like the emoji support, scaling our
                set of fonts and themes, and adding support for custom fonts.
            </Content>
            <Image
                alt="Old design panel (left) vs the new panel (right) that provides a better overview of a custom backgrounds, colors and fonts."
                src={require('../assets/images/typeform/design-theme-panel.png')}
            />
            <Content>
                One of those features was embedding the{' '}
                <a href="https://www.typeform.com/blog/news/unsplash-integration/">
                    Unsplash library
                </a>
                , into the builder to give access to thousands of free, high-quality images. That
                increased the number of custom images uploaded on a form. Activation metrics (MAU)
                increased by 3%, and retention increased by 2% in the first 30 days after the
                release.
            </Content>
            <Image
                alt="Using the Unsplash gallery for custom backgrounds and images"
                src={require('../assets/images/typeform/unsplash-integration.png')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                Manage access
            </Content>
            <Content>
                Many of our customers (event planners, recruiters, researchers, and growth
                marketers) needed a way to manage the availability of their forms, automate their
                processes, meet deadlines, and avoid problems like overbooking.
            </Content>
            <Content>
                We interviewed ~30 customers, who helped us identify known issues and relevant use
                cases. We then defined a Problem Statement manifesto, describing why and how we
                could solve this problem, measuring success, and dividing the estimated effort into
                phases.
            </Content>
            <Content>
                We built an auto-close feature with custom logic, such as setting the number of
                responses or a time/date limit. This feature was gradually released in multiple
                phases: a first beta to focus on the adoption of existing customers, an A/B test to
                focus on adoption and new subscriptions, and finally, the complete release to all
                free and PRO users. That increased new and existing MAU adoption by 2.8% (in the
                first 30 days of the A/B test).
            </Content>
            <Image
                alt="Part of the marketing campaigns to create custom screens once the form is closed."
                src={require('../assets/images/typeform/manage-access-typeform.gif')}
            />
            <Image
                alt="One of the multiple design iterations with manual control and basic customized screen options for PRO users."
                src={require('../assets/images/typeform/manage-acess-first.png')}
            />
        </section>
        <Footer>
            <a href="./new-relic">
                <img
                    loading="lazy"
                    src={require('../assets/images/new-relic/header-3.gif')}
                    alt={'New Relic use case'}
                />
            </a>
            <a href="./playfulbet">
                <img
                    loading="lazy"
                    src={require('../assets/images/playfulbet/header.gif')}
                    alt={'Playfulbet use case'}
                />
            </a>
        </Footer>
    </Layout>
);

export default Typeform;
