import React from 'react';
import Image from '../components/image';
import Layout from '../components/layout';
import Content from '../components/content';

const Work = () => (
    <Layout>
        <nav style={{ gridColumn: '1 / -1' }}>
            <ul>
                <li>
                    <Content kind="h3" tag="h2">
                        GitHub (2022&mdash;2023)
                    </Content>
                </li>
                <li>
                    <Content kind="h3" tag="h2">
                        <a href="./new-relic">New Relic (2019&mdash;2021)</a>
                    </Content>
                </li>
                <li>
                    <Content kind="h3" tag="h2">
                        <a href="./typeform">Typeform (2017&mdash;2019)</a>
                    </Content>
                </li>
                <li>
                    <Content kind="h3" tag="h2">
                        <a href="./playfulbet">Playfulbet (2015&mdash;2017)</a>
                    </Content>
                </li>
            </ul>
            <h4>Side projects</h4>
            <ul>
                <li>
                    <Content kind="h3" tag="h2">
                        <a href="https://boringavatars.com/">Boring avatars</a>
                    </Content>
                </li>
                <li>
                    <Content kind="h3" tag="h2">
                        <a href="https://www.boringvectors.com/">Boring vectors</a>
                    </Content>
                </li>
                <li>
                    <Content kind="h3" tag="h2">
                        <a href="https://www.enrojecerse.com/">Enrojecerse</a>
                    </Content>
                </li>
                <li>
                    <Content kind="h3" tag="h2">
                        <a href="https://unsplash.com/@josepmartins">Textures</a>
                    </Content>
                </li>
            </ul>
        </nav>
    </Layout>
);

export default Work;
