import React from 'react';
import Content from './content';
import styled from 'styled-components';
import { minWidth } from '../variables';

const FooterContent = styled(Content)`
    grid-column: 1 / -1;
`;
const FooterArticle = styled.article`
    grid-column: 1 / -1;
    margin-bottom: 4vw;

    img {
        display: block;
        object-fit: cover;
        width: 100%;
        aspect-ratio: 2/1;
    }

    @media (min-width: ${minWidth}px) {
        grid-column: auto / span 6;
    }
`;

class Footer extends React.Component {
    render() {
        return (
            <>
                <FooterContent kind="lead" tag="h3">
                    More projects
                </FooterContent>
                {this.props.children.map((child, i) => (
                    <FooterArticle key={i}>{child}</FooterArticle>
                ))}
            </>
        );
    }
}

export default Footer;
