import React from 'react';
import Content from '../components/content';
import Image from '../components/image';
import Video from '../components/video';
import Footer from '../components/footer';
import Layout from '../components/layout';
import CaseHeader from '../components/case-header';

const NewRelic = () => (
    <Layout>
        <CaseHeader
            title="New Relic"
            role="Product Design & systems"
            time="2019&ndash;2021"
            imgSrc={require('../assets/images/new-relic/header-3.gif')}
            alt="New Relic One Observability launch retro video"
            description="New Relic is a real-time observability platform that allows websites and apps to track software and hardware performance."
            quote="New Relic’s mission is to instrument the internet and help companies of all sizes and organizations around the world deliver more perfect software."
            quoteAuthor="Lew Cirne"
            quoteAuthorRole="Founder of New Relic"
        />
        <section>
            <Content kind="p" tag="h2">
                Intro
            </Content>
            <Content>
                In 2019, New Relic was building{' '}
                <a href="https://www.youtube.com/watch?v=pQweFFf6cew&amp;ab_channel=NewRelic">
                    New Relic One
                </a>
                , a new platform meant to move beyond traditional monitoring and embrace
                observability, providing a real-time view of all operational data in one place.
            </Content>
        </section>

        <section>
            <Content kind="p" tag="h2">
                Workloads
            </Content>
            <Content>
                My first challenge was leading the design efforts for a brand-new product,
                Workloads.
            </Content>
            <Content>
                New Relic One was built around the concept of{' '}
                <a href="https://docs.newrelic.com/docs/new-relic-one/use-new-relic-one/core-concepts/what-entity-new-relic/">
                    entities
                </a>
                . An entity can be any data-reporting component, like an application, a database
                service, or a group of those items.{' '}
                <a href="https://docs.newrelic.com/docs/new-relic-one/use-new-relic-one/workloads/workloads-isolate-resolve-incidents-faster/">
                    Workloads
                </a>{' '}
                will give the user the ability to group and monitor entities providing aggregated
                health data across your entire system.
            </Content>
            <Content>
                The idea was to show the unique information a group can offer, for instance, the
                most frequent alerts, system health overall, grouping by types, relationships with
                other entities, etc. We worked in one-week sprints to deliver incremental value. We
                started with creating and aggregating entities, grouping metrics by different types
                or showing alerts by condition and priority.
            </Content>
            <Content>
                Our customers clearly knew we needed a quick way to detect essential issues and
                ensure the system's overall health. We introduced an entity map to present all the
                entities where users can group and order them by their health status, and use it as
                a filter.
            </Content>
            <Image
                alt="Entity map explorations. Using a tree-map distribution and gradual color scheme."
                src={require('../assets/images/new-relic/workloads-entity-map-explorations.jpg')}
            />
            <Video
                alt="Entity map interactions to filter down the overview data."
                src={require('../assets/images/new-relic/workloads-entity-map.mp4')}
            />
            <Content>
                Entities present different activity data that could affect the workload status, such
                as an alert, an update/change on their configuration, or reports from scriptable
                tools. For example, a service misconfiguration can cause problems in the main
                Database. We built an Activity Stream visualization to show all that activity and
                its relationships.
            </Content>
            <Image
                alt="Early Activity Stream designs."
                src={require('../assets/images/new-relic/workloads-activity-stream.png')}
            />
            <Video
                alt="Activity Stream coded prototype to test density of information."
                src={require('../assets/images/new-relic/workloads-activity-stream.mp4')}
            />
            <Content>
                Iteration is key in a project built from the ground up. By delivering small amounts
                of product each week, we could easily spot what was missing or what needed to be
                improved. After a year in the making, we delivered a product that went from a simple
                table for a dozen private beta users to a General Availability for all customers in
                New Relic.
            </Content>
            <Image
                alt="Workloads with the entity map, breakdown status and metrics"
                src={require('../assets/images/new-relic/workloads-ga.jpg')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                One Core Design System
            </Content>
            <Content>
                After workloads, I joined One Core's design systems team to set the product design
                direction and provide tools for product teams to deliver a consistent and coherent
                experience.
            </Content>
            <Content>
                Over two years, we developed, scaled, and maintained the design system by providing
                design foundations, a library of user interface components for designers and
                developers, and documented guidelines for common interaction patterns. We also
                supported product teams by providing help and guidance and setting up review
                processes and feature requests.
            </Content>
            <Image
                alt="Documentation site with Foundations, Components usage and examples, API, Guides and more"
                src={require('../assets/images/new-relic/one-core-documentation.png')}
            />
            <Video
                alt="Step component for sequential, multi-step onboardings."
                src={require('../assets/images/new-relic/one-core-step-component.mp4')}
            />
            <Video
                alt="Tile component for grouping collection of related data."
                src={require('../assets/images/new-relic/one-core-tile-component.mp4')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                A11y
            </Content>
            <Content>
                In 2021, New Relic commissioned an external accessibility assessment, which
                confirmed almost a thousand accessibility issues of different severity levels across
                the board. Problems range from poor focus management to low-contrast text to a lack
                of screen reader support.
            </Content>
            <Content>
                We built an accessibility foundation right at the core of the design system. This
                foundation will help teams create accessible UIs and improve the existing ones by
                providing components and documentation.
            </Content>
            <Content>
                I worked on a revisited color palette to deliver increased contrast between light
                and dark shades of the scale, expand the number of accessible combinations, and
                update middle shades to have more harmonious and perceptually uniform colors.
            </Content>
            <Content>
                I created the palette using a color appearance model (
                <a href="https://gramaz.io/d3-cam02/">CIECAM</a>) designed to model human color
                perception and consider the contrast levels to define accessible color combinations
                (<a href="https://www.w3.org/WAI/WCAG2AA-Conformance">WCAG AA and AAA levels</a>
                ). These changes fixed over six hundred issues on the low foreground and background
                contrast.
            </Content>
            <Image
                alt="Old vs new Color palette"
                src={require('../assets/images/new-relic/one-core-palette-comparison.gif')}
            />
            <Image
                alt="One Core accessibility and color usage documentation."
                src={require('../assets/images/new-relic/one-core-color-palette-documentation.png')}
            />
        </section>
        <Footer>
            <a href="./typeform">
                <img
                    loading="lazy"
                    src={require('../assets/images/typeform/header-3.gif')}
                    alt={'Typeform use case'}
                />
            </a>
            <a href="./playfulbet">
                <img
                    loading="lazy"
                    src={require('../assets/images/playfulbet/header.gif')}
                    alt={'Playfulbet use case'}
                />
            </a>
        </Footer>
    </Layout>
);

export default NewRelic;
