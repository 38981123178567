import React from 'react';
import Content from '../components/content';
import Footer from '../components/footer';
import Layout from '../components/layout';
import Image from '../components/image';
import Video from '../components/video';
import CaseHeader from '../components/case-header';

const Playfulbet = () => (
    <Layout>
        <CaseHeader
            title="Playfulbet"
            role="Product Design & Frontend"
            time="2015&ndash;2017"
            imgSrc={require('../assets/images/playfulbet/header.gif')}
            alt="Soccer, tennis and football animated balls"
            description="Playfulbet is a social gaming platform where users can play against traditional sports events, such as FIFA or NBA events."
            quote="Our platform should be fun and create a unique experience with a strong focus on social interaction."
            quoteAuthor="Paco Maldonado"
            quoteAuthorRole="COO at Playfulbet"
        />
        <section>
            <Content kind="p" tag="h2">
                Intro
            </Content>
            <Content>
                Playfulbet{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://web.archive.org/web/20131028165349/http://playfulbet.com/competiciones/liga-bbva-16"
                >
                    was built in 2015
                </a>{' '}
                as an MVP with more than 10,000 user subscriptions within the first six weeks. The
                main goal was to redesign and strengthen the platform's foundations to improve its
                usability on every device and make it scalable for future functionalities.
            </Content>
        </section>
        <section>
            <Content kind="p" tag="h2">
                Foundations
            </Content>
            <Content>
                My first task was to define a new navigation structure (considering future features)
                and a clear flow from signup to activation. To increase retention, it was vital to
                guide new users to complete some basic actions, such as playing a new game or
                updating their profile, as one of the first steps after signup.
            </Content>
            <Content>
                Within the first two months, I designed a new UI kit, defined the navigation, built
                a responsive layout, and introduced a single-page application experience.
            </Content>
            <Image
                alt="The new UI Guide: typography scale, color palette and some basic components."
                src={require('../assets/images/playfulbet/ui-kit.jpg')}
            />
            <Image
                alt="The user's home page uses the new kit as a reference."
                src={require('../assets/images/playfulbet/dashboard.jpg')}
            />
            <Content>
                The platform went viral some weeks later through marketing campaigns, going from 10K
                new signups to more than 150K in less than a month. Activation metrics increased
                with the new layout/navigation and some new features: a search by event, featured
                events, and better load times. Increasing retention and monetization became our next
                significant milestone.
            </Content>
        </section>
        <section>
            <Content kind="p" tag="h2">
                Chat
            </Content>
            <Content>
                Early feedback from users was critical to understand what they were looking for,
                what was missing, what was useless, and what we could improve. We built an internal
                chat for each event and dedicated some time every day to asking our users. The
                feedback from those interactions gave us direction on what to build, improve, or
                prioritize.
            </Content>
            <Content>
                We later used tools like{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://www.hotjar.com/">
                    Hotjar
                </a>{' '}
                and{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://www.typeform.com/">
                    Typeform
                </a>{' '}
                to automate specific flows for gathering and grouping feedback.
            </Content>
        </section>
        <section>
            <Content kind="p" tag="h2">
                Custom Dashboard
            </Content>
            <Content>
                With the dashboard panel, users could see recommended events (based on the last
                events played, users' and friends' preferences, etc.) and check their events in
                play, activity, and pending results.
            </Content>
            <Image
                alt="Home page with dashboard user metrics."
                src={require('../assets/images/playfulbet/custom-dashboard.jpg')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                Gamification
            </Content>
            <Content>
                We introduced an essential gaming experience based using levels to increase
                retention metrics. Levels were based on the number of played events or achievements,
                such as played events at certain sports, leagues, or special events.
            </Content>
            <Image
                alt="Public metrics on each user profile section."
                src={require('../assets/images/playfulbet/gamification.jpg')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                Advertising
            </Content>
            <Content>
                Once we improved the retention metrics, we jumped into advertising as a monetization
                channel. We were cautious about what and where to show it, as we wanted to keep the
                experience on the platform. The best results came from encouraging users to get
                points by watching short promotional videos or playing sponsored mini-games.
            </Content>
            <Image
                alt="We selected ads to offer a playful sport-related experience."
                src={require('../assets/images/playfulbet/ads.jpg')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                Mobile app
            </Content>
            <Content>
                We developed and designed a mobile app to facilitate engagement with the platform,
                as the majority of our users access it from mobile devices.
            </Content>
            <Image
                alt="First realease of the Android mobile app with basic features."
                src={require('../assets/images/playfulbet/playfulbet-mobile.jpg')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                Favorite teams and competitions
            </Content>
            <Content>
                For new customers, a typical flow after the signup was to find a match in a
                particular competition. Users from Barcelona were likely to play with their friends
                in F.C. Barcelona or R.C.D Espanyol events rather than the Japanese J1 football
                competition.
            </Content>
            <Content>
                We built a system to allow users to mark competitions and teams as favorites and
                give them more control and personalization over the platform. We could recommend
                upcoming events and competitions to users with similar affinities.
            </Content>
            <Content>
                With this new feature, we gained retention, as users could have a list of their
                preferred teams to check the status and activate, too, as they find more value in
                playing with known competitions and teams.
            </Content>
            <Image
                alt="Home page recommendation modules."
                src={require('../assets/images/playfulbet/teams-1.jpg')}
            />
            <Image
                alt="Marking a team or a league as a favorite."
                src={require('../assets/images/playfulbet/teams-2.jpg')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                Interaction
            </Content>
            <Content>
                Our goal was to build a platform where users would play between friends. The look
                and feel should be playful and friendly. That's why CTA's and buttons, in general,
                were prominent and colorful, and so was the interaction with them. One of our
                inspirations was to simulate real arcade machines and video games interfaces.
            </Content>
            <Video
                alt="Part of the interactions when playing a sports event."
                src={require('../assets/images/playfulbet/video-1.mp4')}
            />
        </section>
        <section>
            <Content kind="p" tag="h2">
                Testing
            </Content>
            <Content>
                Building a solid and modular UI was crucial, which led us to work on new features
                and test them in the early stages. Our prototypes were actual code pushed in
                production to specific users (at some point, we had a small database of users who
                wanted to test beta features), and that gave us early insights beforehand to move
                forward with some ideas or not.
            </Content>
        </section>
        <Footer>
            <a href="./typeform">
                <img
                    loading="lazy"
                    src={require('../assets/images/typeform/header-3.gif')}
                    alt={'Typeform use case'}
                />
            </a>
            <a href="./new-relic">
                <img
                    loading="lazy"
                    src={require('../assets/images/new-relic/header-3.gif')}
                    alt={'New Relic use case'}
                />
            </a>
        </Footer>
    </Layout>
);

export default Playfulbet;
